/**
* Created by tudou on 2020/4/12 18:38.
*/
<template>
  <div class="base-content">
    <form-frame
      :reset-visible="false"
      :submit-visible="false"
    >
      <div slot="content">
        <query-frame
          :add-visible="false"
          :current="page.current"
          :total="page.total"
          @on-query="handleQuery"
          @on-reset="handleReset"
          @on-page-change="handlePageChange"
          @on-size-change="handleSizeChange"
        >
          <!-- 查询条件开始 -->
          <template slot="query">
            <query-item label="操作类型">
              <a-select allowClear v-model="query.logType">
                <a-select-option :value="0">
                  充值
                </a-select-option>
                <a-select-option :value="2">
                  开单
                </a-select-option>
                <a-select-option :value="3">
                  取消
                </a-select-option>
              </a-select>
            </query-item>
            <query-item label="更新时间">
              <i-date-picker v-model="query.duration"></i-date-picker>
            </query-item>
          </template>
          <!-- 查询条件结束 -->
          <!-- 查询结果 开始 -->
          <template slot="table">
            <i-table :columns="columns" :dataSource="dataList">
            </i-table>
          </template>
          <!-- 查询结果 结束 -->
        </query-frame>
      </div>

    </form-frame>

  </div>
</template>

<script>
import { memberBill } from '@/api/member';
import queryMixin from '@/mixin/queryMixin';
import FormFrame from '@/components/frame/FormFrame.vue';
import IDatePicker from '../../components/IDatePicker.vue';
export default {
  name: 'MemberBill',
  mixins: [queryMixin],
  components: {
    FormFrame,
    IDatePicker
  },
  props: {},
  data() {
    return {
      query: {
        logType:null,
        shopId: null,
        realName: null,
        phone: null,
        duration: [],
      },
      // 默认展示表格
      columns: [
        {
          title: '订单号',
          dataIndex: 'sourceNo',
          key: 'sourceNo',
          ellipsis: true,
          width: 200
        },
        {
          title: '消费门店',
          dataIndex: 'shopName',
          key: 'shopName',
          ellipsis: true
        },
        {
          title: '商品名称',
          dataIndex: 'goodsName',
          key: 'goodsName',
          ellipsis: true
        },
        {
          title: '商品数量',
          dataIndex: 'itemCount',
          key: 'itemCount',
          ellipsis: true
        },
        {
          title: '金额（RM）',
          dataIndex: 'amount',
          key: 'amount',
          ellipsis: true
        },
        {
          title: '实际金额（RM）',
          dataIndex: 'realAmount',
          key: 'realAmount'
        },
        {
          title: '赠送金额（RM）',
          dataIndex: 'giftAmount',
          key: 'giftAmount'
        },
        {
          title: '优惠金额（RM）',
          dataIndex: 'discountAmount',
          key: 'discountAmount'
        },
        {
          title: '可退金额（RM）',
          dataIndex: 'remainAmount',
          key: 'remainAmount'
        },
        {
          title: '总金额（RM）',
          dataIndex: 'sumAmount',
          key: 'sumAmount'
        },
        {
          title: '操作类型',
          dataIndex: 'logType',
          key: 'logType',
          customRender: (text) => {
            let result = ""
            switch(text){
              case 0:
                 result = '充值'
                    break;
              case 2:
                result = '开单'
                    break;
              case 3:
                  result = '取消'
                    break;
              default:
                break;
            }
            return result
          }
        },
        {
          title: '更新时间',
          dataIndex: 'updateTime',
          key: 'updateTime',
          ellipsis: true,
          width: 180,
        },
      ],
      dataList: []
    };
  },
  computed: {
    memberId() {
      return this.$route.params.memberId;
    },
  },
  watch: {},
  created() {
    console.log(this.memberId)
    this.handleQuery();
  },
  mounted() {
  },
  destroyed() {
  },
  methods: {
    // 获取会员列表
    getData() {
      this.params.pageIndex = this.page.current;
      this.params.pageSize = this.page.pageSize;
      this.params.memberId = this.memberId;
      this.dataList = [];
      console.log(this.params)
      memberBill(this.params).then((data) => {
        console.log(data)
        const { totalCount, list } = data;
        this.page.total = totalCount;
        list.forEach((item) => {
          item.key = item.memberAmountLogId
        });
        this.dataList = list;
      });
    },
    // 查看成绩
    handleScore(row) {
      this.$router.push({
        name: 'memberScore',
        params: {
          matchSerial: row.matchSerial,
          userName: row.userName,
          openId: row.openId,
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>

</style>
